import styles from './Point.module.css';
import logo_small from "../../assets/img/logo_small.png";

const Point = ({children}) => {
    return (
        <div className={styles.point}>
            <img src={logo_small} alt={'Logo'}/>
            <span>{children}</span>
        </div>
    );
}

export default Point;