import styles from './Heading.module.css';
import logo_small from "../../assets/img/logo_small.png";

const Heading = ({children, hideLogo}) => {
    return (
        <div className={styles.heading}>
            {!hideLogo && <img src={logo_small} alt={'Logo'}/>}
            <span>{children}</span>
        </div>
    );
}

export default Heading;