import styles from './Home.module.css';
import CustomButton from "../CustomButton/CustomButton";
import logo_black from '../../assets/img/logo_black.png';
import home_banner from '../../assets/img/home_banner.png';
import google_play from '../../assets/img/google-play-badge.png';
import apple_store from '../../assets/img/apple_store_badge.svg';
import {RiLinkedinFill, RiTwitterFill, RiInstagramLine, RiFacebookFill, RiCloseLine} from "react-icons/ri";
import {IoCallSharp} from 'react-icons/io5';
import QuotationMark from "../QuotationMark/QuotationMark";
import {Fragment, useEffect, useState} from "react";
import axios from "axios";
import SocialPanel from "../SocialPanel/SocialPanel";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";

const Home = () => {
    const [quotesData, setQuotesData] = useState([]);
    const [shareClicked, setShareClicked] = useState(false);
    const [moodClicked, setMoodClicked] = useState(false);
    const [moodText, setMoodText] = useState('');
    const [helpShareClicked, setHelpShareClicked] = useState(false);
    const [helpShown, setHelpShown] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        axios.get('https://ugood-backend.herokuapp.com/quotes').then(res => {
            setQuotesData(res.data);
            setIndex(Math.floor((Math.random() * (res.data.length - 1))));
        });
    }, []);

    const setMood = moodTextValue => {
        setMoodText(moodTextValue);
        setMoodClicked(true);
    }

    return (
        <div className={styles.home} id={'home'}>
            <div className={styles.content}>
                <div className={styles.homeDownloadContainer}>
                    {quotesData.length > 0 ? <Fragment>
                        <div className={styles.homeQuoteContainer}>
                            <QuotationMark opening/>
                            <p className={styles.homeQuote}>
                                {quotesData[index].quote}
                            </p>
                            <QuotationMark/>
                        </div>
                        {!shareClicked ?
                            <CustomButton text={'SHARE NOW'} onClick={() => setShareClicked(true)}/> :
                            <SocialPanel quote={quotesData[index].quote} setShowPanel={setShareClicked}/>
                        }
                    </Fragment> : null}
                    <p className={styles.homeDownloadTxt}>
                        Download the <img src={logo_black} className={styles.homeLogo} alt={'Logo'}/> App Now
                    </p>

                    <div className={styles.homeBadgeContainer}>
                        <a href={'https://apps.apple.com/us/app/u-good-tap-check-in-reply/id1543589359'} target={'_blank'} rel={'noreferrer'}><img src={apple_store} alt={'Apple Store'}/></a>
                        <a href={'https://play.google.com/'} target={'_blank'} rel={'noreferrer'}><img src={google_play} alt={'Google Play'} className={styles.downloadBadge}/></a>
                    </div>
                </div>

                <div className={styles.homeBannerContainer}>
                    <img className={styles.homeBanner} src={home_banner} alt={'Home Banner'}/>
                    <div className={styles.actionsContainer}>
                        <img src={logo_black} className={styles.actionLogo} alt={'Logo'}/>
                        <div className={styles.actionButtons}>
                            {!moodClicked ? (
                                <Fragment>
                                    <CustomButton fontSize={'0.8rem'} padding={'6px 12px'} text={"I'm good"} onClick={() => setMood("I'm feeling good!")}/>
                                    <CustomButton fontSize={'0.8rem'} padding={'6px 12px'} bgColor={'#EACD2D'} text={"Mmm, I don't know"} onClick={() => setMood("Mmm, I don't know how I'm feeling right now!")}/>
                                    <CustomButton fontSize={'0.8rem'} padding={'6px 12px'} bgColor={'#FF0000'} text={"I'm not good"} onClick={() =>setHelpShown(true)}/>
                                </Fragment>
                            ) : <SocialPanel quote={moodText} setShowPanel={setMoodClicked}/>}
                        </div>
                    </div>
                </div>

                <div className={styles.homeSocialContainer}>
                    <span title={'Share on Twitter'}>
                        <TwitterShareButton url={'https://ugood.app/'} title={'Ugood'} hashtags={['ugood']}>
                            <RiTwitterFill/>
                        </TwitterShareButton>
                    </span>

                    <span title={'Share on Facebook'}>
                        <FacebookShareButton url={'https://ugood.app/'} quote={'Ugood'} hashtag={'#ugood'}>
                            <RiFacebookFill/>
                        </FacebookShareButton>
                    </span>

                    <span title={'Share on LinkedIn'}>
                        <LinkedinShareButton url={'https://ugood.app/'} title={'Ugood'} summary ={'Ugood'}>
                            <RiLinkedinFill/>
                        </LinkedinShareButton>
                    </span>

                    <span title={'Share on Instagram'}>
                        <a href={'https://www.instagram.com/'} target={'_blank'} rel={'noreferrer'}>
                            <RiInstagramLine/>
                        </a>
                    </span>
                </div>
            </div>

            {helpShown ? (
                <div className={styles.helpContainer}>
                    <div className={styles.helpBox}>
                        <div className={styles.helpHeader}>
                            <img src={logo_black} className={styles.actionLogo} alt={'Logo'}/>
                            <span onClick={() => setHelpShown(false)}><RiCloseLine/></span>
                        </div>
                        <p>Need to talk to someone?</p>
                        <p>(Warmlines)</p>
                        <p className={styles.normal}>or</p>
                        <p className={styles.noUnderline}><span className={styles.helpIcon}><IoCallSharp/></span>988</p>
                        {quotesData.length > 0 ? <div className={styles.helpQuote}>
                            <QuotationMark opening/>
                            <h1>{quotesData[index].quote}</h1>
                            <QuotationMark/>
                        </div> : null}

                        <div className={styles.helpBtn}>
                            {!helpShareClicked ?
                                <CustomButton text={'SHARE NOW'} onClick={() => setHelpShareClicked(true)}/> :
                                <SocialPanel quote={quotesData[index].quote} setShowPanel={setHelpShareClicked}/>
                            }
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default Home;