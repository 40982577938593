import styles from './InfoContainer.module.css';
import Heading from "../Heading/Heading";
import Point from "../Point/Point";

const InfoContainer = ({title, heading, para, children}) => {
    return (
        <div className={styles.info}>
            <Heading>{title}</Heading>

            <p className={styles.heading}>
                {heading}
            </p>

            <p className={styles.para}>
                {para}
            </p>

            <div className={styles.points}>
                {children}
            </div>
        </div>
    );
}

export default InfoContainer;