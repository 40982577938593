import styles from './Footer.module.css';
import logo_white from '../../assets/img/logo_white.png';

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.green}>
                <div className={styles.yellow}>
                    <div className={styles.red}></div>
                </div>
            </div>
            <div className={styles.footerContent}>
                <img src={logo_white} alt={'Logo'}/>
                <p>&copy; 2022 | UGood | All Rights Reserved</p>
            </div>
        </div>
    );
}

export default Footer;