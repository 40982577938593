import styles from './Navbar.module.css';
import logo_black from '../../assets/img/logo_black.png';
import CustomButton from "../CustomButton/CustomButton";

const Navbar = () => {
    const refreshPage = () => window.location.reload();

    return (
        <div className={styles.navbar}>
            <img src={logo_black} alt={'Logo'} className={styles.logo} onClick={refreshPage}/>
            <div className={styles.menu}>
                <a href={'#about'} className={styles.menuItem}>About</a>
                {/*<a href={'#features'} className={styles.menuItem}>Features</a>*/}
                <a href={'#quotes'} className={styles.menuItem}>Quotes</a>
                <a href={'#'}><CustomButton text={'DOWNLOAD APP NOW'}/></a>
            </div>
        </div>
    );
}

export default Navbar;