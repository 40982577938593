import styles from './SocialPanel.module.css';
import {
    RiFacebookFill,
    RiLinkM,
    RiMailLine,
    RiTwitterFill,
    RiWhatsappLine,
    RiCloseFill
} from "react-icons/ri";
import {
    FacebookShareButton,
    EmailShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import {CopyToClipboard} from "react-copy-to-clipboard";

const SocialPanel = ({quote, setShowPanel}) => {
    const handleClick = () => {
        setShowPanel(false);
    }

    return (
        <div className={styles.socialPanel}>
            <span title={'Share on Twitter'} onClick={handleClick}>
                <TwitterShareButton url={'https://ugood.app/'} title={quote} hashtags={['ugood']}>
                    <RiTwitterFill/>
                </TwitterShareButton>
            </span>

            <span title={'Share on Facebook'} onClick={handleClick}>
                <FacebookShareButton url={'https://ugood.app/'} quote={quote} hashtag={'#ugood'}>
                    <RiFacebookFill/>
                </FacebookShareButton>
            </span>

            <span title={'Share on WhatsApp'} onClick={handleClick}>
                <WhatsappShareButton url={'https://ugood.app/'} title={quote} separator={' | '}>
                    <RiWhatsappLine/>
                </WhatsappShareButton>
            </span>

            <span title={'Share on Email'} onClick={handleClick}>
                <EmailShareButton url={'https://ugood.app/'} subject={'Quote from UGood'} body={quote} separator={' | '}>
                    <RiMailLine/>
                </EmailShareButton>
            </span>

            <span title={'Copy'} onClick={handleClick}>
                <CopyToClipboard text={quote}>
                    <RiLinkM/>
                </CopyToClipboard>
            </span>

            <span title={'Close'} onClick={handleClick}>
                <RiCloseFill/>
            </span>
        </div>
    );
}

export default SocialPanel;