import styles from './Quotes.module.css';
import Heading from "../Heading/Heading";
import QuotationMark from "../QuotationMark/QuotationMark";
import {GrNext, GrPrevious} from "react-icons/gr";
import CustomButton from "../CustomButton/CustomButton";
import {useEffect, useState} from "react";
import axios from "axios";
import SocialPanel from "../SocialPanel/SocialPanel";

const Quotes = () => {
    const [quotesData, setQuotesData] = useState([]);
    const [shareClicked, setShareClicked] = useState(false);
    const [index, setIndex] = useState(1);

    useEffect(() => {
        axios.get('https://ugood-backend.herokuapp.com/quotes').then(res => {
            setQuotesData(res.data);
            setIndex(Math.floor((Math.random() * (res.data.length-2)) + 1));
        });
    }, []);

    const changeQuote = action => {
        if (action === 'next'){
            if (index < quotesData.length - 1){
                return setIndex(index+1);
            }
            return setIndex(0);
        }
        else if (action === 'prev'){
            if (index > 0){
                return setIndex(index-1);
            }
            return setIndex(quotesData.length-1)
        }
    }

    return (
        quotesData.length > 0 ? (
            <div id={'quotes'} className={styles.quotes}>
                <div className={styles.content}>
                    <div className={styles.headingContainer}>
                        <Heading>Daily Quotes</Heading>
                    </div>
                    <div className={styles.quotesContainer}>
                        <span className={styles.sideQuote}>{quotesData[index === 0 ? quotesData.length-1 : index-1].quote}</span>
                        <QuotationMark opening/>
                        <span className={styles.mainQuote}>{quotesData[index].quote}</span>
                        <QuotationMark/>
                        <span className={styles.sideQuote}>{quotesData[index === quotesData.length-1 ? 0 : index+1].quote}</span>
                    </div>
                    <div className={styles.actionBtnContainer}>
                        <button className={styles.actionBtn} onClick={() => changeQuote('prev')}><GrPrevious/></button>
                        <button className={styles.actionBtn} onClick={() => changeQuote('next')}><GrNext/></button>
                    </div>
                    {!shareClicked ?
                        <CustomButton text={'SHARE NOW'} onClick={() => setShareClicked(true)}/> :
                        <SocialPanel quote={quotesData[index].quote} setShowPanel={setShareClicked}/>
                    }
                </div>
            </div>) : null
    );
}

export default Quotes;