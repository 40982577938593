import styles from './CustomButton.module.css';

const CustomButton = ({text, bgColor, color, padding, fontSize, onClick}) => {
    return (
        <button style={{
            backgroundColor: bgColor,
            color,
            padding,
            fontSize
        }} className={styles.btn} onClick={onClick ? onClick : ()=>{}}>{text}</button>
    );
}

export default CustomButton;