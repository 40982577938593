import styles from './About.module.css';
import about_banner from '../../assets/img/about_banner.png';
import logo_black from '../../assets/img/logo_black.png';
import InfoContainer from "../InfoContainer/InfoContainer";
import Point from "../Point/Point";

const About = () => {
    return (
        <div className={styles.about} id={'about'}>
            <div className={styles.content}>
                <div className={styles.aboutImgContainer}>
                    <img src={logo_black} alt={'Logo'} className={styles.aboutLogo}/>
                    <img src={about_banner} alt={'About Banner'} className={styles.aboutBanner}/>
                </div>

                <InfoContainer
                    title={'About us'} 
                    heading={'U;GOOD? Check-in, Show love!'}
                    para={"Conceived  out of the physical and emotional distancing that arrived with the 2020 pandemic, as\n" +
                        "                        well as the racial and ethnic disparity in access to mental health, the U; Good? founders dared\n" +
                        "                        to ask: What if we can create an App that only uses your contacts to check-in on you, and in turn\n" +
                        "                        allows you to check-in on your contacts? What if by the colors they emit, our contacts can signal\n" +
                        "                        their mood, prompting us to check-in on them?"}>
                    <Point>The founders took action and built the platform in just 48 hours!</Point>

                    <Point>
                        The App has been lauded for the simplicity of its design. Users simply open the App, and it
                        checks in on you. Users have the option of choosing one of three color moods: Green (feeling good),
                        Yellow (ok) and Red (not ok). When a user chooses the “Red” mood option, they are prompted to a
                        “warm line”, volunteer-manned phone lines, or the national suicide hotline, to manage dire mental
                        health crises.  There is an option to enter contacts into a group for easy check-ins and simple
                        pre-set responses for responding to check-ins.
                    </Point>

                    <Point>
                        The trademarked U; Good? checkmark logo icon is synonymous with mental health and urban culture.
                        Conversations surrounding mental health, once stigmatized in Black and Brown urban communities
                        is now normalized.  The U;Good? logo is poised to become the universal symbol of urban wellness.
                        The semicolon in the spelling of U;Good? is a nod to Project Semicolon, a movement that reminds
                        us all that no matter the struggle or obstacle, our sentence is not over.
                    </Point>
                </InfoContainer>
            </div>
        </div>
    );
}

export default About;