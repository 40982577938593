import {Fragment} from "react";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
// import Features from "./components/Features/Features";
import Quotes from "./components/Quotes/Quotes";
import Footer from "./components/Footer/Footer";

const App = () => {
    return (
        <Fragment>
            <Navbar/>
            <Home/>
            <About/>
            {/*<Features/>*/}
            <Quotes/>
            <Footer/>
        </Fragment>
    );
}

export default App;
